:root {
  --toastify-color-info: #007aff !important;
  --toastify-color-success: #56c568 !important;
  --toastify-color-warning: #e5b800 !important;
  --toastify-color-error: #ff3b3b !important;
  --toastify-toast-width: 340px !important;
}
.Toastify__toast-container {
  padding: 0 !important;
}
.Toastify__toast {
  padding: 12px 15px !important;
  min-height: 40px !important;
  align-items: center !important;
  border-radius: 5px !important;
}
.Toastify__toast-body {
  padding: 0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 20px !important;
}
.Toastify__toast--success::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/success%20toasts.svg'); */
  content: url('../assets/icons/SuccessToasts.svg');
}
.Toastify__toast--error::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/Error%20Toasts.svg'); */
  content: url('../assets/icons/ErrorToasts.svg');
}

.Toastify__toast--warning::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/Warning%20Toasts.svg'); */
  content: url('../assets/icons/WarningToasts.svg');
}
.Toastify__toast--info::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/Info%20toasts.svg'); */
  content: url('../assets/icons/InfoToasts.svg');
}
.Toastify__toast--info::before,
.Toastify__toast--success::before,
.Toastify__toast--error::before,
.Toastify__toast--warning::before {
  position: relative;
  z-index: 100000;
  left: 0px;
  margin-right: 8px;
  height: 22px;
}
.Toastify__toast--info::after,
.Toastify__toast--success::after,
.Toastify__toast--error::after,
.Toastify__toast--warning::after {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/cross%20toasts.svg'); */
  content: url('../assets/icons/CrossToasts.svg');
  position: absolute;
  right: 15px;
  height: 22px;
}
