@import url(https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
/* Fonts */

.intercom-hide-launcher {
  display: none !important;
}

.review_link {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: end;
          justify-content: end;
  margin-top: -3.1rem;
  text-decoration: none;
  -webkit-align-items: end;
          align-items: end;
  font-size: 1.2rem;
  padding: 8px;
}
.icon_width {
  width: 24px;
  padding-top: 0.3rem;
  cursor: pointer;
}
.icon_width_like {
  width: 24px;
  padding-top: 0.3rem;
  color: grey;
  cursor: pointer;
}

.action_icons {
  margin-left: auto;
}
.gap_warring {
  color: #f64646;
  font-weight: 600;
}

:root {
  --toastify-color-info: #007aff !important;
  --toastify-color-success: #56c568 !important;
  --toastify-color-warning: #e5b800 !important;
  --toastify-color-error: #ff3b3b !important;
  --toastify-toast-width: 340px !important;
}
.Toastify__toast-container {
  padding: 0 !important;
}
.Toastify__toast {
  padding: 12px 15px !important;
  min-height: 40px !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  border-radius: 5px !important;
}
.Toastify__toast-body {
  padding: 0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 20px !important;
}
.Toastify__toast--success::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/success%20toasts.svg'); */
  content: url(/static/media/SuccessToasts.ecae4dae.svg);
}
.Toastify__toast--error::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/Error%20Toasts.svg'); */
  content: url(/static/media/ErrorToasts.98e6265d.svg);
}

.Toastify__toast--warning::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/Warning%20Toasts.svg'); */
  content: url(/static/media/WarningToasts.0ff5fa64.svg);
}
.Toastify__toast--info::before {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/Info%20toasts.svg'); */
  content: url(/static/media/InfoToasts.008d4f16.svg);
}
.Toastify__toast--info::before,
.Toastify__toast--success::before,
.Toastify__toast--error::before,
.Toastify__toast--warning::before {
  position: relative;
  z-index: 100000;
  left: 0px;
  margin-right: 8px;
  height: 22px;
}
.Toastify__toast--info::after,
.Toastify__toast--success::after,
.Toastify__toast--error::after,
.Toastify__toast--warning::after {
  /* content: url('https://deepsync-static-assets.sfo2.digitaloceanspaces.com/Dashboard/Dashboard/Icons/cross%20toasts.svg'); */
  content: url(/static/media/CrossToasts.5158ac34.svg);
  position: absolute;
  right: 15px;
  height: 22px;
}

.loader {
  width: 50px;
  height: 50px;
  border: 5px solid white;
  border-top: 5px solid #007aff;
  border-radius: 50%;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loadingContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  text-align: center;
  position: absolute;
  top: 40%;
  left: 42%;
}

.loading {
  font-weight: 600;
  font-size: 25px;
  color: #007aff;
  margin-left: 12px;
  margin-top: 15px;
  font-family: 'Inter';
}
/*# sourceMappingURL=spinner.css.map */

.backgrounds {
  width: 100%;
  height: 300px;
  z-index: -1;
  position: absolute;
  top: 0;
  background-color: #f8f7f7;
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .backgrounds {
    height: 500px !important;
  }
}

@media screen and (min-width: 1200px) {
  .backgrounds {
    height: 500px !important;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) {
  .backgrounds {
    height: 430px !important;
  }
}

@media screen and (min-width: 1800px) {
  .backgrounds {
    height: 540px !important;
  }
}

.headContainers {
  margin-top: -10px;
  width: 100%;
  height: 85vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.box {
  width: 479px;
  height: 358px;
  border: 2px solid #3377ff;
  background-color: white;
  box-shadow: 0px 0px 50px -9px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

@media screen and (max-width: 1024px) and (min-width: 769px) {
  .box {
    margin-top: 50px;
  }
}

@media screen and (min-width: 1200px) {
  .box {
    margin-top: 50px;
  }
}

@media screen and (max-width: 1400px) and (min-width: 1201px) {
  .box {
    margin-top: 0px;
  }
}

@media screen and (min-width: 1800px) {
  .box {
    margin-top: 50px;
  }
}

.images {
  width: 52px;
  height: 47px;
  margin-top: 50px;
}

.title {
  font-family: 'Inter';
  margin-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #3f3f3f;
}

.infos {
  width: 287px;
  height: auto;
  margin-top: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125.5%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #8b8b8b;
}

.btnss {
  width: 210px;
  margin-top: 25px;
  border: 1px solid #3377ff;
  background-color: white;
  text-align: center;
  padding: 8px 0px;
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.0025em;
  color: #3377ff;
}

.btnss:hover {
  color: #3568d4;
}
/*# sourceMappingURL=confirmation.css.map */

.backgrounds {
  width: 100%;
  height: 300px;
  z-index: -1;
  position: absolute;
  top: 0;
  background-color: #f8f7f7;
}

.headContainers {
  margin-top: -10px;
  width: 100%;
  height: 85vh;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.box {
  width: 479px;
  height: 358px;
  border: 2px solid #3377ff;
  background-color: white;
  box-shadow: 0px 0px 50px -9px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}

.images {
  width: 52px;
  height: 47px;
  margin-top: 50px;
}

.title {
  font-family: 'Inter';
  margin-top: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #3f3f3f;
}

.infos {
  width: 287px;
  height: auto;
  margin-top: 22px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 125.5%;
  text-align: center;
  letter-spacing: -0.01em;
  color: #8b8b8b;
}

.btnss {
  width: 210px;
  margin-top: 25px;
  border: 1px solid #3377ff;
  background-color: white;
  text-align: center;
  padding: 8px 0px;
  border-radius: 6px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.0025em;
  color: #3377ff;
}

.btnss:hover {
  color: #3568d4;
}
/*# sourceMappingURL=invalidlink.css.map */

