/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

.intercom-hide-launcher {
  display: none !important;
}

.review_link {
  display: flex;
  justify-content: end;
  margin-top: -3.1rem;
  text-decoration: none;
  align-items: end;
  font-size: 1.2rem;
  padding: 8px;
}
.icon_width {
  width: 24px;
  padding-top: 0.3rem;
  cursor: pointer;
}
.icon_width_like {
  width: 24px;
  padding-top: 0.3rem;
  color: grey;
  cursor: pointer;
}

.action_icons {
  margin-left: auto;
}
.gap_warring {
  color: #f64646;
  font-weight: 600;
}
